const projects = [
  {
    id: 1,
    title: 'IncidenTracker',
    description: 'UI for the app IncidenTracker, an app for storing and tracking theft incidents for petrol stations.',
    imageURL: 'https://cdna.artstation.com/p/assets/images/images/071/375/016/large/darragh-nolan-incidentracker-imgurl.jpg?1705039305',
    contentURL: [
      'https://cdnb.artstation.com/p/assets/images/images/071/012/495/large/darragh-nolan-d-o.jpg',
      'https://cdna.artstation.com/p/assets/images/images/071/012/510/large/darragh-nolan-theft.jpg',
      'https://cdnb.artstation.com/p/assets/images/images/071/012/505/large/darragh-nolan-note.jpg',
      'https://i.ibb.co/KWmqpTG/D-OIdleS.png',
      'https://i.ibb.co/5XDw9pF/D-OPressed-S.png',
      'https://i.ibb.co/1M1LZLY/D-OThumbnail-S.png',
      'https://i.ibb.co/kGBQVXt/Theft-Idle-S.png',
      'https://i.ibb.co/6nSJWLQ/Theft-Pressed-S.png',
      'https://i.ibb.co/J57qwsG/Theft-Thumbnail-S.png',
      'https://i.ibb.co/JtBW957/Note-Idle-S.png',
      'https://i.ibb.co/zxxqtvp/Note-Pressed-S.png',
      'https://i.ibb.co/59cfCYV/Note-Thumbnail-S.png'
    ],
    videoURL:'https://www.youtube.com/embed/yXhGysglTLw?si=U6rqGzpswXASJm5a',
    tags: ['UI Art', 'Web Development', 'UX', 'Mobile Applications'],
    subTags: ['React.Js', 'SQL', 'Express.Js', 'Node.Js', 'Illustrator', 'Krita'],
    // Add more project details as needed
  },
  {
    id: 2,
    title: 'Illustrations',
    description: 'Various 2D illustrations and renders I have done over the years.',
    imageURL: 'https://cdna.artstation.com/p/assets/images/images/036/061/810/medium/darragh-nolan-milovjordancolour.jpg',
    contentURL: [
      'https://cdna.artstation.com/p/assets/images/images/037/999/974/large/darragh-nolan-jordanstreetware.jpg?1621900481',
      'https://cdna.artstation.com/p/assets/images/images/076/839/786/large/darragh-nolan-darragh-nolan-uudualscreensaverfinalcropped.jpg?1717954793',
      'https://cdnb.artstation.com/p/assets/images/images/040/807/347/large/darragh-nolan-smokebreak.jpg?1629922558',
      'https://pbs.twimg.com/media/E1cjmxaXoAEWqxi?format=jpg&name=4096x4096',
      'https://cdnb.artstation.com/p/assets/images/images/076/839/791/large/darragh-nolan-darragh-nolan-cynthiagarchompstarplatinum-1.jpg?1717954444',
      'https://cdna.artstation.com/p/assets/images/images/076/839/862/large/darragh-nolan-chrismoltisanti.jpg?1717954632',
      'https://cdna.artstation.com/p/assets/images/images/031/472/320/large/darragh-nolan-ejmugcqxyaale4s.jpg?1603735730',
      'https://cdna.artstation.com/p/assets/images/images/036/061/810/medium/darragh-nolan-milovjordancolour.jpg',
      'https://cdna.artstation.com/p/assets/images/images/040/807/420/large/darragh-nolan-dwanorendernobackground.jpg?1629922774',
      // 'https://pbs.twimg.com/media/EV2L1JPXgAIeZ8X?format=jpg&name=4096x4096',
      'https://cdna.artstation.com/p/assets/images/images/076/839/794/large/darragh-nolan-linkbokoblion.jpg?1717954454',
      'https://cdnb.artstation.com/p/assets/images/images/031/472/317/large/darragh-nolan-20201003-164613.jpg?1603735971',
    ],
    tags: ['Illustration'],
    subTags: [ 'Krita', 'Photoshop'],
    // Add more project details as needed
  },
  {
    id: 8,
    title: 'Animation Reel',
    description: 'Collection of 3D animations I made for over the years',
    imageURL: 'https://cdna.artstation.com/p/assets/images/images/071/508/742/large/darragh-nolan-darragh-nolan-portfolio-shot.jpg?1705346521',
    contentURL: [
      'https://cdna.artstation.com/p/assets/images/images/071/508/742/large/darragh-nolan-darragh-nolan-portfolio-shot.jpg?1705346521',
    ],
    videoURL:'https://www.youtube.com/embed/gXPXQvk9A_4?si=_bFCo65lbc8V2mdf',
    ThreeDModels: [
      // '../models/BatLurker.glb',
      '../models/Duano.glb',
      '../models/DJDemoniq.glb',
      '../models/FlyFella2.glb',
      '../models/Squirtle2.glb',
      '../models/KSGun.glb',
      // '../models/Iyrah.glb',
    ],
    ThreeDAlbedos: [
      // '../textures/BatLurkerABO.png',
      '../textures/DuanoABO.jpeg',
      '../textures/DJDemoniqABO.png',
      '../textures/testABO.png',
      '../textures/SquirtleABO.png',
      '../textures/KSGun.png',
      // '../textures/IyrahABO(1).png',
    ],    
    ThreeDOpacitys: [
      // '../textures/testOPY.png',
      '../textures/DuanoOPY.jpeg',
      '../textures/testOPY.png',
      '../textures/testOPY.png',
      '../textures/SquirtleOPY.png',
      '../textures/testOPY.png',
      // '../textures/testOPY.png',
    ],
    ThreeDMetalness: [
      // '../textures/testOPY.png',
      '../textures/DuanoMTC.jpeg',
      '../textures/DJDemoniqMTC.png',
      '../textures/testOPY.png',
      '../textures/testOPY.png',
      '../textures/testOPY.png',
      // '../textures/testOPY.png',
    ],
    ThreeDRoughness: [
      // '../textures/testMTC.png',
      '../textures/DuanoRGS.jpeg',
      '../textures/DJDemoniqMTC.png',
      '../textures/testMTC.png',
      '../textures/testMTC.png',
      '../textures/testMTC.png',
      // '../textures/testMTC.png',
    ],
    ThreeDEmissive: [
      // '../textures/testOPY.png',
      '../textures/DuanoESE.jpeg',
      '../textures/DJDemoniqESE.png',
      '../textures/testMTC.png',
      '../textures/testMTC.png',
      '../textures/testMTC.png',
      // '../textures/testOPY.png',
    ],
    UVList: 1,
    modelProperties: [ 
      // { posX: 0, posY: 0, posZ: -1500, rotX: Math.PI / 10, rotY: 0, rotZ: 0 , scale: 0.5, animSpeed: 1, isAnimated: true, camPosY: 250 },
      { posX: 0, posY: 0, posZ: -125, rotX: Math.PI / -4, rotY: 0, rotZ: Math.PI / -8, scale: 50, animSpeed: 1.1, isAnimated: true, camPosY: 90 }, 
      { posX: 0, posY: 0, posZ: -200, rotX: Math.PI / 10, rotY: Math.PI / 1.15, rotZ: Math.PI / -1 , scale: -50, animSpeed: 1, isAnimated: true, camPosY: 0 }, 
      { posX: 0, posY: 0, posZ: -700, rotX: 0, rotY: Math.PI / -1.3, rotZ: Math.PI / -1 , scale: -50, animSpeed: 1, isAnimated: true, camPosY: -100 },
      { posX: 0, posY: 0, posZ: -500, rotX: 0, rotY: Math.PI / 1.15, rotZ: Math.PI / -1 , scale: -50, animSpeed: 1, isAnimated: true, camPosY: 30 },
      { posX: 0, posY: 0, posZ: -120, rotX: 0, rotY: Math.PI / -2, rotZ: 0 , scale: 1, animSpeed: 1, isAnimated: true, camPosY: 0 },
      // { posX: 0, posY: 0, posZ: -23, rotX: Math.PI / -25, rotY: Math.PI / 3, rotZ: 0 , scale: 1, animSpeed: 1, isAnimated: true, camPosY: -4 },
      // Add properties for other models
    ],
    tags: ['Game Development', '3D Animation'],
    subTags: ['Blender', 'Substance Painter', '3DS Max', 'Krita', 'Unity'],
    credits: ['James Bryan', 'Steven Briscese', 'Sarah Long', 'James Skinner', 'Blue Diamond Gaming LLC'],
    creditCategory: ['Robotic Arm Model', 'Lurker Model and Textures', 'Gauntlets Model and Textures', 'Weapons Models', 'Harbinger Assets'],
    creditSource: ['https://www.jamesbryan.net', 'https://briscesesteven.wixsite.com', 'https://www.artstation.com/co2inferno', 'https://www.artstation.com/jamesskinner', 'https://bdsgaming.net'],
  },
  {
    id: 4,
    title: 'Vape Drop',
    description: 'Some of the features I added to the Vape Drop site as a freelance Web Developer. I added a bulk discount feature and a Blue Light card feature that allowed users to upload an image of their Blue Light card to the site to get 15% off their order.',
    imageURL: 'https://i.ibb.co/2SjQ1fP/Screenshot-2025-01-06-190004.png',
    contentURL: [
      'https://i.ibb.co/34GvT9v/Screenshot-2025-01-06-190004.png',
      'https://i.ibb.co/qdcBDHQ/Screenshot-2025-01-06-190004.png',
    ],
    tags: ['Web Development'],
    subTags: ['WordPress'],
    credits: ['Darragh Nolan'],
    creditCategory: ['Programmer','Designer','UX'],
    creditSource: ['http://vapedropuk.com/'],
  },
  {
    id: 6,
    title: 'Post-Apocalyptic Platformer',
    description: 'A 2D platformer made for Android mobile devices made with Unity. Art by Sonali Mathur, design by Karan Gupta and programming by me.',
    imageURL: 'https://img.itch.zone/aW1nLzE0NTM3NzA0LnBuZw==/347x500/G0%2B8%2Bp.png',
    contentURL: [
      'https://img.itch.zone/aW1nLzE0NTM3NzA0LnBuZw==/347x500/G0%2B8%2Bp.png',
      'https://img.itch.zone/aW1hZ2UvMTgzOTM0Ni8xNDY2OTE0OS5qcGc=/347x500/xteN%2Bb.jpg',
      'https://img.itch.zone/aW1hZ2UvMTgzOTM0Ni8xNDY2OTE1MC5qcGc=/347x500/U17K8Q.jpg',
      'https://img.itch.zone/aW1hZ2UvMTgzOTM0Ni8xNDY2OTE1MS5qcGc=/347x500/BEA4lf.jpg',
      'https://img.itch.zone/aW1hZ2UvMTgzOTM0Ni8xNDY3NTc2My5qcGc=/347x500/l9Ln1q.jpg',
    ],
    videoURL:'https://www.youtube.com/embed/IbZgBuL-ZUw?si=qL52rFAfvFV3K-9y',
    tags: ['Game Development', 'UX', 'Mobile Applications'],
    subTags: ['Unity', 'C#'],
    credits: ['Sonali Mathur', 'Karan Gupta'],
    creditCategory: ['Art', 'Design'],
    creditSource: ['https://www.instagram.com/sonalixart/', 'https://www.linkedin.com/in/karan-gupta-designer/'],
  },
  {
    id: 7,
    title: 'Neon Demon Illustrations',
    description: 'Collection of illustrations I made for Neon Demon: A 3D first person shooter I made in college.',
    imageURL: 'https://cdnb.artstation.com/p/assets/images/images/036/062/471/large/darragh-nolan-banner-1.jpg?1616620335',
    contentURL: [
      'https://cdnb.artstation.com/p/assets/images/images/036/062/471/large/darragh-nolan-banner-1.jpg?1616620335',               //NeonDemonBanner
      'https://cdnb.artstation.com/p/assets/images/images/036/062/409/large/darragh-nolan-billboardadtranscend.jpg?1616620239',   //ConzUmerCola
      'https://cdna.artstation.com/p/assets/images/images/036/062/402/large/darragh-nolan-billboardaddescend.jpg?1616620233',     //YouAbsoluteTrog
      'https://cdna.artstation.com/p/assets/images/images/037/052/334/large/darragh-nolan-nightclubaddone.jpg?1619363166',         //NineCircles
      'https://cdnb.artstation.com/p/assets/images/images/036/062/419/large/darragh-nolan-duanconecptdark-2.jpg?1616620246',       //DuaneSmoking
      'https://cdna.artstation.com/p/assets/images/images/036/062/424/large/darragh-nolan-belileprofilepic.jpg?1616620258'         //Belial
    ],
    videoURL:'https://www.youtube.com/embed/Zim-yXAKP5Y?si=ujGwo5rIJYJ-bQUg',
    tags: ['Game Development', 'Illustration'],
    subTags: ['Krita', 'Unity'],
    credits: ['James Bryan', 'Shane Carrol', 'Conor Jespon', 'Eoin McSharry', 'Cormac FitzPatrick'],
    creditCategory: ['Artist and Designer', 'Designer and Programmer', 'Programmer, Designer and Artist', 'Producer, Artist and Designer', 'Music'],
    creditSource: ['https://jamesbryan.itch.io/', 'https://slimshaney.itch.io/', 'https://papajepo.itch.io/', 'https://delta-rayquaza.itch.io/', ' https://www.instagram.com/cormacfitzz/?igshid=n09wsabj1bol'],
  },
  {
    id: 3,
    title: 'Deft Hands like the Wings of a Butterfly',
    description: 'A cinemetic I made in 2019.',
    imageURL: 'https://cdna.artstation.com/p/assets/images/images/039/681/834/20210719133213/smaller_square/darragh-nolan-screenshot3.jpg',
    contentURL: [
      'https://cdna.artstation.com/p/assets/images/images/039/681/834/20210719133213/smaller_square/darragh-nolan-screenshot3.jpg',
      'https://cdnb.artstation.com/p/assets/images/images/039/665/135/large/darragh-nolan-screenshot1.jpg?1626570029',
      'https://cdnb.artstation.com/p/assets/images/images/039/665/655/large/darragh-nolan-screenshot2.jpg?1626572930'
    ],
    videoURL: 'https://www.youtube.com/embed/TXBZrwJOOq8?si=BuOOmhM_3IGzAICd',
    ThreeDModels: [
      '../models/Iyrah.glb',
      '../models/Iyrah.glb',
    ],
    ThreeDAlbedos: [
      '../textures/IyrahABO(1).png',
      '../textures/IyrahABO(2).png',
      '../textures/IyrahABO(3).png',
      '../textures/IyrahABO(4).png',
      '../textures/IyrahABO(5).png',
      '../textures/IyrahABO(6).png',
    ],    
    ThreeDOpacitys: [
      '../textures/testOPY.png',
      '../textures/testOPY.png',
      '../textures/testOPY.png',
      '../textures/testOPY.png',
      '../textures/testOPY.png',
      '../textures/testOPY.png',
    ],
    ThreeDMetalness: [
      '../textures/testOPY.png',
      '../textures/testOPY.png',
      '../textures/testOPY.png',
      '../textures/testOPY.png',
      '../textures/testOPY.png',
      '../textures/testOPY.png',
    ],
    ThreeDRoughness: [
      '../textures/testMTC.png',
      '../textures/testMTC.png',
      '../textures/testMTC.png',
      '../textures/testMTC.png',
      '../textures/testMTC.png',
      '../textures/testMTC.png',
    ],
    ThreeDEmissive: [
      '../textures/testOPY.png',
      '../textures/testOPY.png',
      '../textures/testOPY.png',
      '../textures/testOPY.png',
      '../textures/testOPY.png',
      '../textures/testOPY.png',
    ],
    UVList: 6,
    modelProperties: [      
      { posX: 0, posY: 0, posZ: -23, rotX: Math.PI / -25, rotY: Math.PI / 3, rotZ: 0 , scale: 1, animSpeed: 1, isAnimated: true, camPosY: -4 },      
      { posX: 0, posY: 0, posZ: -23, rotX: Math.PI / -25, rotY: Math.PI / 3, rotZ: 0 , scale: 1, animSpeed: 1, isAnimated: true, camPosY: -4 },
      // Add properties for other models
    ],
    tags: ['3D Animation'],
    subTags: ['Blender', 'Substance Painter'],
  },
  {
    id: 9,
    title: 'Coppercrown',
    description: 'A 2D detective game I made in 2019 with Unity.',
    imageURL: 'https://img.itch.zone/aW1nLzMyNjM2NTYucG5n/315x250%23c/ZsrgSo.png',
    contentURL: [
      'https://i.ibb.co/x7L90c2/Walter.png',
      'https://i.ibb.co/LNh7zTS/Coppercrown-Snr.png',
      'https://img.itch.zone/aW1hZ2UvNjEzNDI4LzMyNjM1MjkucG5n/original/8kLIuu.png',
      'https://i.ibb.co/yR92L3k/Comissioner.png',
      'https://i.ibb.co/vwJ8G4W/Maria.png',
      'https://img.itch.zone/aW1hZ2UvNjEzNDI4LzMyNjM1MzEucG5n/original/LwZUsv.png',
      'https://img.itch.zone/aW1hZ2UvNjEzNDI4LzMyNjM1MzIucG5n/original/31veTr.png',
      'https://i.ibb.co/vXPgFyY/Junior.png',
      'https://i.ibb.co/89TYBPN/Butler.png',
      'https://img.itch.zone/aW1hZ2UvNjEzNDI4LzMyNjM1MzAucG5n/original/9UrCYz.png',
      'https://img.itch.zone/aW1hZ2UvNjEzNDI4LzMyNjM1MzMucG5n/original/zySdKT.png'
    ],
    videoURL:'https://www.youtube.com/embed/O2gOy2BxbOk?si=UrdI174DX0Eu0R-v',
    tags: ['Game Development', 'UX', 'Illustration'],
    subTags: ['Unity', 'C#', 'Krita'],
    credits: ['Darragh Nolan','The Curious Case of Count Coppercrown'],
    creditCategory: ['Creator','Game Source'],
    creditSource: ['https://dazariath.itch.io/the-curious-case-of-count-coppercrown'],
  },
  {
    id: 10,
    title: 'KANISHA',
    description: 'Some illustrations and designs I did for the band KANISHA.',
    imageURL: 'https://i.ibb.co/rkRkbYN/Logo-Plain.jpg',
    contentURL: [
      'https://i.ibb.co/k2cRB5v/Kanish-Poster-Minimilist-2-01.jpg',
      'https://i.ibb.co/JB0bksp/Kanish-Poster-Square-Minimilist-02.jpg',
      'https://i.ibb.co/Gnv01yk/Kanish-Poster-01-01-01.jpg',
      'https://i.ibb.co/gDqx9gN/Logo-04-03.jpg',
      'https://i.ibb.co/rkRkbYN/Logo-Plain.jpg',
    ],
    tags: ['Illustration'],
    subTags: ['Illustrator', 'Krita'],
    credits: ['Darragh Nolan','KANISHA'],
    creditCategory: ['Creator','Game Source'],
    // creditSource: ['https://dazariath.itch.io/the-curious-case-of-count-coppercrown'],
  },
  {
    id: 11,
    title: 'Parsnip Pops',
    description: 'A small game I made in 2023.',
    imageURL: 'https://i.ibb.co/HnjqZtC/Screenshot-2024-03-16-070237.png',
    contentURL: [
      'https://i.ibb.co/Qb8BkzN/GameLogo.png',
      'https://i.ibb.co/CQMpgJp/Game-Asset2.png',
      'https://i.ibb.co/9qwmx9m/Game-Asset1.png',
      'https://i.ibb.co/Ks1c7YN/Screenshot-2024-03-16-070342.png',
      'https://i.ibb.co/wM072WN/Screenshot-2024-03-16-065913.png',
      'https://i.ibb.co/vLkBwpR/Screenshot-2024-03-16-070208.png',
      'https://i.ibb.co/GkMzLkP/Screenshot-2024-03-16-0702212.png',
      'https://i.ibb.co/HnjqZtC/Screenshot-2024-03-16-070237.png',
    ],
    tags: ['Illustration', 'Game Development', 'Mobile Development'],
    subTags: ['Illustrator', 'Krita', 'Unity'],
    credits: ['Darragh Nolan','Eoin McSharry', 'Caoimhe Hill'],
    creditCategory: ['Artist','Designer','Programmer'],
    creditSource: ['https://dazariath.itch.io','https://delta-rayquaza.itch.io/'],
  },
  {
    id: 12,
    title: 'Maguire`s Whiskey Website',
    description: 'HTML design for a hypothetical artisian Irish Whiskey website that delivers drink kits to customers.',
    imageURL: 'https://cdna.artstation.com/p/assets/images/images/071/197/056/large/darragh-nolan-mw-favicon.jpg',
    contentURL: [
      'https://cdna.artstation.com/p/assets/images/images/071/197/056/medium/darragh-nolan-mw-favicon.jpg?1704675978',
      'https://cdnb.artstation.com/p/assets/images/images/071/196/927/large/darragh-nolan-logoheader.jpg',
      'https://cdnb.artstation.com/p/assets/images/images/071/198/739/large/darragh-nolan-mw-desktoplandingpage.jpg',
      'https://cdnb.artstation.com/p/assets/images/images/071/474/953/medium/darragh-nolan-mw-mobilelandingpage.jpg?1705280292',
      'https://cdna.artstation.com/p/assets/images/images/071/196/934/large/darragh-nolan-logoheadermonitor.jpg'
    ],
    videoURL:'https://www.youtube.com/embed/yHCjgjzyGjk?si=hCyaI1GN5Uxsv_uF',
    tags: ['UI Art', 'Web Development', 'UX'],
    subTags: ['HTML', 'Illustrator', 'Krita', 'Photoshop'],
  },
  {
    id: 5,
    title: 'GrangeMobile App',
    description: 'A prototype app I made for storing information about lectures, classes, students and lecturers within the Grangegorman campus. This uses news and weather APIs, a MAMP server to connect to the database and was made with Angular using Android Studio and Ionic.',
    imageURL: 'https://www.irishtimes.com/resizer/dTxc-VG4dOsORgqdeDwPyUdviaE=/1600x1200/filters:format(jpg):quality(70)/cloudfront-eu-central-1.images.arcpublishing.com/irishtimes/GEZDF3RXYXEXZN2TZELPUSYJFM.jpg',
    contentURL: [
    ],
    videoURL:'https://www.youtube.com/embed/TYzk-LdKOjs?si=PZs0nOpiIdDeLyNt',
    tags: ['Web Development', 'UX', 'Mobile Applications'],
    subTags: ['Angular', 'Android Studio', 'PHP', 'Node.Js'],
  },
  // Add more projects as needed
];

export default projects;